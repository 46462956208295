<template>
  <banner text="Schüler-Portal" icon="graduation cap"/>

  <h2>Registrieren</h2>
  <div class="ui segment">
    <form class="ui form error" v-on:submit.prevent="register" v-if="registrationTokenInfo">
      <div class="ui segment">
        Hallo {{ registrationTokenInfo.name }},<br/><br/>
        hier kannst du dich für das Schüler-Portal registrieren.
        Bitte gib deine Email und ein Passwort deiner Wahl mit mindestens 8 Zeichen ein.
      </div>

      <div v-bind:class="[invalidEmailError || takenEmailError ? 'field error' : 'field']">
        <label>E-mail</label>
        <input type="email" required v-model="registrationForm.email" placeholder="maxmuster@mail.de"/>
      </div>
      <div class="ui error message" v-if="invalidEmailError">
        <p>Bitte gib eine gültige Email Adresse ein.</p>
      </div>
      <div class="ui error message" v-if="takenEmailError">
        <p>Diese Email Adresse existiert bereits.</p>
      </div>

      <div v-bind:class="[passwordLengthError ? 'field error' : 'field']">
        <label>Passwort</label>
        <input type="password" required v-model="registrationForm.password" placeholder="secret*123"/>
      </div>
      <div class="ui error message" v-if="passwordLengthError">
        <p>Das Passwort muss mindestens 8 Zeichen lang sein.</p>
      </div>

      <div v-bind:class="[passwordConfirmationError ? 'field error' : 'field']">
        <label>Passwort bestätigen</label>
        <input type="password" required v-model="registrationForm.password_confirmation" placeholder="secret*123"/>
      </div>
      <div class="ui error message" v-if="passwordConfirmationError">
        <p>Die Bestätigung stimmt nicht mit dem Passwort überein.</p>
      </div>

      <button type="submit" class="ui green button">Registrieren</button>
    </form>

    <div class="ui red inverted segment" v-else>
      Sorry, ohne gültiges Token kannst du dich hier nicht registrieren.<br/>
      Bitte wende dich an den Administrator deiner Schule.
    </div>
  </div>
</template>

<script>
import store from "../store";
import Banner from "../components/Banner";

export default {
  components: {Banner},
  data: function () {
    return {
      registrationForm: {
        email: null,
        password: null,
        password_confirmation: null,
        registration_token: null
      },
      registrationTokenInfo: null,
      errors: null
    }
  },
  methods: {
    register: function () {
      store.dispatch('auth/register', this.registrationForm)
          .then(() => {
            this.$router.push('/login');
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    }
  },
  computed: {
    invalidEmailError: function () {
      return this.errors && this.errors.email
          && this.errors.email.includes('Email-Adresse muss eine gültige Email-Adresse sein.');
    },
    takenEmailError: function () {
      return this.errors && this.errors.email
          && this.errors.email.includes('Email-Adresse existiert bereits.');
    },
    passwordLengthError: function () {
      return this.errors && this.errors.password
          && this.errors.password.includes('Das Passwort muss mindestens 8 Zeichen lang sein.');
    },
    passwordConfirmationError: function () {
      return this.errors && this.errors.password
          && this.errors.password.includes('Die Passwort Bestätigung stimmt nicht überein.');
    }
  },
  created() {
    const regToken = this.$route.query.token;
    if (regToken) {
      store.dispatch('auth/getRegistrationTokenInfo', regToken)
          .then((tokenInfo) => {
            this.registrationTokenInfo = tokenInfo;
            this.registrationForm.registration_token = tokenInfo.token
          });
    }
  }
}
</script>
